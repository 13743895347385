// 课程
<template>
  <div class="about">
    <h1>文档管理</h1>
    <div class="handle-box">
      <span>搜索条件:</span>
      <el-input v-model.trim="query.name"
                placeholder="输入行业ID、行业名称"
                class="handle-input mr10 w100"></el-input>
      <span>行业:</span>
      <el-select v-model="query.industryId"
                 placeholder="请选择">
        <el-option key="bbk"
                   label="请选择"
                   value=""></el-option>
        <el-option :key="item.id"
                   v-for="(item) in allIndustry"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <span>职业:</span>
      <el-select v-model="query.occupationId"
                 placeholder="请选择">
        <el-option key="bbk"
                   label="请选择"
                   value=""></el-option>
        <el-option :key="item.id"
                   v-for="(item) in allOcc"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>
      <span>职位:</span>
      <el-select v-model="query.positionId"
                 placeholder="请选择">
        <el-option key="bbk"
                   label="请选择"
                   value=""></el-option>
        <el-option :key="item.id"
                   v-for="(item) in allPosition"
                   :label="item.name"
                   :value="item.id"></el-option>
      </el-select>

      <el-button type="primary ml10"
                 icon="el-icon-search"
                 @click="searchData">搜索</el-button>
    </div>
    <!-- 表格开始 -->
    <div class="add-title"
         @click="addList(0)">
      +添加文档
    </div>

    <el-table :data="list"
              border
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange">
      <el-table-column prop="id"
                       label="文档ID"
                       width="100"
                       align="center">
      </el-table-column>
      <el-table-column prop="id"
                       label="封面图片"
                       align="center">
        <template #default="scope">
          <el-image class="pre"
                    :src="scope.row.coverUrl"
                    :preview-src-list="[scope.row.coverUrl]"></el-image>
        </template>

      </el-table-column>
      <el-table-column prop="name"
                       label="文档名称"
                       align="center">
      </el-table-column>
      <el-table-column prop="title"
                       label="文档标题"
                       align="center">
      </el-table-column>
      <el-table-column prop="sketch"
                       label="文档简述"
                       align="center">
      </el-table-column>
      <el-table-column prop="copywriting"
                       label="宣传文案"
                       align="center">
      </el-table-column>
      <el-table-column prop="industryName"
                       label="行业"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.industryName== null ? "不限": scope.row.industryName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="occupationName"
                       label="职业"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.occupationName== null ? "不限": scope.row.occupationName }}</span>
        </template>

      </el-table-column>
      <el-table-column prop="positionName"
                       label="职位"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.positionName== null ? "不限": scope.row.positionName }}</span>
        </template>

      </el-table-column>
      <el-table-column prop="sceneName"
                       label="场景"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.sceneName== null ? "不限": scope.row.sceneName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="salary"
                       label="薪资"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.salary== 0 ? "不限": scope.row.salary }}</span>
        </template>

      </el-table-column>
      <el-table-column prop="membersName"
                       label="所需会员"
                       align="center">
      </el-table-column>
      <el-table-column prop="recommend"
                       label="是否推荐"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.recommend ? "是": "否" }}</span>
        </template>

      </el-table-column>

      <el-table-column prop="isEnable"
                       label="当前状态"
                       align="center">
        <template #default="scope">
          <span> {{ scope.row.enable ? "启用中": "禁用中" }}</span>
        </template>

      </el-table-column>
      <el-table-column label="操作"
                       width="360"
                       align="center">
        <template #default="scope">
          <el-button type="text"
                     icon="el-icon-edit"
                     @click="addList(1,scope.row)">修改</el-button>
          <el-button type="text"
                     icon="el-icon-circle-close"
                     v-if="scope.row.enable"
                     class="red"
                     @click="changeListStatus(scope.row.id,scope.row)">禁用</el-button>
          <el-button type="text"
                     icon="el-icon-circle-check"
                     v-else
                     @click="changeListStatus( scope.row.id,scope.row)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->
    <div class="add-title"
         @click="addList(0)">
      +添加文档
    </div>
    <div class="page">
      <el-pagination background
                     layout="total, prev, pager, next"
                     :current-page="pageIndex"
                     :page-size="20"
                     :total="total"
                     @current-change="handlePageChange"></el-pagination>

    </div>
  </div>
</template>
<script>
import { getDocArray, getDocStatus, getAllIndustry, getAllOcc, getAllPosition } from "../../api/index";
export default {
  name: 'text',
  data () {
    return {
      phoneEditor: '',
      name: '',
      form: {
        region: "bbk"
      },
      query: {
        name: "",
        industryId: "",
        occupationId: "",
        positionId: "",
        pageIndex: 1,
      },
      list: [
        {
          id: 1,
          name: " 总监",
        }
      ],
      allIndustry: [],
      allOcc: [],
      allPosition: [],


    }
  },
  created () {
    this.getData();
    this.getLabels();
    let str = "<p>国庆</p>https://pptres.qyzhuomian.com/images-admin/00cb960aca804053ae87f21df8c60a14.jpg"
    let s = str.indexOf("http");
    let strE = str.slice(s);
    console.log(strE);

  },
  methods: {
    getData () {
      let params = {
        name: this.query.name,    //数字则查id，文字则查名称，或者标题
        industryId: this.query.industryId,// 行业
        occupationId: this.query.occupationId, // 职业
        positionId: this.query.positionId, // 职位
        pageIndex: this.query.pageIndex,
        pageSize: 20
      }
      getDocArray(params).then(res => {
        console.log(res);
        if (res.code === 0) {

          this.list = res.data.data;
          this.total = res.data.total;
          this.list && this.list.map(item => {
            let url = item.coverUrl;
            console.log(url, "8888744");
            let s = url.lastIndexOf("http");
            let strE = url.slice(s);
            item.coverUrl = strE;
          })

        }
      })
    },
    // 获取下拉框数据
    getLabels () {
      getAllIndustry().then(res => {
        if (res.code == 0) {
          this.allIndustry = res.data;
        }
      });
      getAllOcc().then(res => {
        if (res.code == 0) {
          this.allOcc = res.data;
        }
      });
      getAllPosition().then(res => {
        if (res.code == 0) {
          this.allPosition = res.data;
        }
      });

    },
    addList (flag, row) {
      let url = "";
      if (flag == 0) {
        // 新加
        url = window.location.origin + '/docEadit';
      } else {
        // 修改
        url = window.location.origin + '/docEadit?id=' + row.id;
      }
      window.open(url, '_blank');
    },
    // 分页
    handlePageChange (val) {
      console.log(val);
      this.query.pageIndex = val;
      this.getData();
    },
    // 搜索
    searchData () {
      this.query.pageIndex = 1;
      this.getData();
    },
    // 启用 禁用
    changeListStatus (id, row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let params = {
            id: id
          };
          getDocStatus(params).then(res => {
            if (res.code == 0) {
              this.$notify.success({
                title: "提示",
                message: "操作成功"
              });
              this.getData();

            } else {
              this.$notify.warning({
                title: "提示",
                message: "操作失败"
              });

            }
          })
        })
        .catch(() => { });
    }
  },
}
</script>

<style lang="scss">
.handle-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
}
.add-title {
  margin-top: 8px;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  // border: 1px solid #409eff;
  background: #409eff;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.save-good {
  border: 1px solid #67c23a;
  background: #67c23a;
  color: #fff;
}
.page {
  padding: 10px;
  display: flex;
}
.ml10 {
  margin-left: 10px;
}
</style>